import React from "react";

interface ChecklistItem {
  label: string;
  completed: boolean;
}

interface OnboardingChecklistProps {
  items: ChecklistItem[];
}

const OnboardingChecklist: React.FC<OnboardingChecklistProps> = ({ items }) => {
  return (
    <div
      style={{
        width: "20%",
        backgroundColor: "#0E1234", // Dark background for sidebar
        padding: "20px",
        color: "white",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h2 style={{ marginBottom: "20px", borderBottom: "2px solid #EC572E" }}>
        Onboarding Checklist
      </h2>
      <ul
        style={{
          listStyleType: "none",
          padding: 0,
          margin: 0,
        }}
      >
        {items.map((item, index) => (
          <li
            key={index}
            style={{
              padding: "10px 0",
              borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{item.label}</span>
            <span
              style={{
                width: "20px",
                height: "20px",
                borderRadius: "50%",
                backgroundColor: item.completed ? "#28a745" : "#EC572E", // Green if completed, orange if not
                color: "white",
              }}
            >
              {item.completed ? "✔" : ""}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OnboardingChecklist;

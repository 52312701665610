import React, { useEffect, useRef} from "react";
import { gsap } from "gsap";

interface OnboardingTextAnimationProps {
  onboardingText: string[];
  videoSrc: string;
}

const OnboardingTextAnimation: React.FC<OnboardingTextAnimationProps> = ({
  onboardingText,
  videoSrc,
}) => {
  const onboardingTextRef = useRef<HTMLDivElement | null>(null);
  const botsaVideoContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Split text into lines, words, chars
    gsap.from(onboardingTextRef.current!.querySelectorAll(".line"), {
      y: '100%',
      opacity: 0,
      duration: 1,
      ease: 'sine.inOut',
      stagger: 1, // Stagger the lines for a nice effect
    });

    // Show GIF after text animation completes
    gsap.to(botsaVideoContainerRef.current, {
      opacity: 1,
      duration: 2,
      ease: 'power2.out',
      delay: onboardingText.length * 0.8, // Delay based on number of lines
    });

  }, [onboardingText]);

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      {/* Onboarding Text */}
      <div
        ref={onboardingTextRef}
        style={{
          position: "absolute",
          top: "1%",
          left: "31%",
          transform: "translateX(-50%)", // Center text horizontally
          textAlign: "left",
          fontSize: "20px",
          fontWeight: "bold",
          fontFamily: "segoe ui",
        }}
      >
        {onboardingText.map((line, index) => (
          <div
          key={index}
          className="line" // Class to target for animation
          style={{
            marginBottom: "10px", // Add space between lines
            overflow: "hidden", // Prevents text overflow before animation
          }}
          dangerouslySetInnerHTML={{ __html: line }} // Renders the HTML inside the string
        />
      ))}
    </div>

      {/* Botsa GIF */}
      <div
        ref={botsaVideoContainerRef}
        style={{
          position: "absolute",
          top: "60%", // Move GIF below the typed text
          left: "49%",
          transform: "translate(-50%, -50%)",
          opacity: 0, // Initially hidden
        }}
      >
        <video src={videoSrc}
        autoPlay
        loop
        muted
        playsInline
        style={{ width: "1130px", height: "auto" }} >
          Hmmm, looks like your machine is having issues playing my video, you'll have to make due with this GIF for now.
          </video>
      </div>
    </div>
  );
};

export default OnboardingTextAnimation;
"use client";

import React, { useState, useEffect, createElement } from "react";
import { PageWithLayout } from "@shesha-io/reactjs";
import OnboardingTextAnimation from "@/components/onboarding-components/onboardingTextAnimation";
import OnboardingChecklist from "@/components/onboarding-components/onboardingChecklist";
import OnboardingEndingAnimation from "@/components/onboarding-components/onboardingEndingAnimation";
import { useAuth } from "@shesha-io/reactjs";

interface OnboardingComponentProps {
  onboardingText: string[];
  videoSrc: string;
}

const Home: PageWithLayout<{}> = () => {
  const auth = useAuth();
  const token = auth.token;
  const authorisation = `Bearer ${token}`;
  const videoUrl = `https://pdrocketfuelai.blob.core.windows.net/videotutorials/Add Subject Area.mp4`;
  const backendUrl = process.env.BACKEND_URL ?? 'http://localhost:21021';

  const [onboardingComponent, setOnboardingComponent] = useState<React.FC<OnboardingComponentProps> | null>(null);
  const [onboardingText, setOnboardingText] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [onboardingItemTitle, setOnboardingItemTitle] = useState<string>("");
  const [checklistItems, setChecklistItems] = useState<any[]>([]);

  useEffect(() => {
    const fetchOnboardingText = async () => {
      try {
        const response = await fetch(`${backendUrl}/OnboardingAppService/OnboardingFlowStep`, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            Authorization: authorisation,
          },
        });

        const result = await response.json();
        setOnboardingItemTitle(result.result.message);

        if (result.success) {
          setOnboardingText(result.result.data);
          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error('Error fetching onboarding text:', error);
        return false;
      } finally {
        setLoading(false);
      }
    };

    const fetchOnboardingChecklist = async () => {
      try {
        const response = await fetch(`${backendUrl}/OnboardingAppService/OnboardingChecklist`, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            Authorization: authorisation,
          },
        });

        const result = await response.json();
        console.log(result);

        if (result.success) {
          setChecklistItems(result.result.data);
        }
      } catch (error) {
        console.error('Error fetching checklist items:', error);
      }
    };

    const fetchComponentFromAPI = async () => {
      const isSuccess = await fetchOnboardingText();
      await fetchOnboardingChecklist(); 

      if (isSuccess) {

        setOnboardingComponent(() => OnboardingTextAnimation);
      } else {

        setOnboardingComponent(() => (props: OnboardingComponentProps) => (
          <OnboardingEndingAnimation
            {...props}
            scene1Text2="Congratulations! You've successfully completed the onboarding process—you're all set to begin your journey with me! Head to the chat to get started."
            imgSrc="https://pdrocketfuelai.blob.core.windows.net/images/botsalogo.png"
            gifSrc="https://pdrocketfuelai.blob.core.windows.net/gifs/KnowledgebaseUploadDocumentonline-video-cutter.com-ezgif.com-video-to-gif-converter.gif"
          />
        ));
      }
    };

    fetchComponentFromAPI();
  }, [authorisation]);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        minHeight: "94vh",
        backgroundColor: "#f4f4f9",
      }}
    >
      {/* Onboarding Screen */}
      <div
        style={{
          width: "80%",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        
        <h1>{onboardingItemTitle}</h1>
        
        {loading ? (
          <p>Loading...</p>
        ) : onboardingComponent ? (
          createElement(onboardingComponent, {
            onboardingText: onboardingText,
            videoSrc: videoUrl,
          })
        ) : (
          <p>No component available</p>
        )}
      </div>

      {/*Onboarding Side Panel */}
      <OnboardingChecklist items={checklistItems} />
    </div>
  );
};

export default Home;
import React, { useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { gsap } from "gsap";

interface OnboardingEndingAnimationProps {
  onboardingText: string[];
  scene1Text2: string;   // Single text for scene 2
  imgSrc: string;        // Single image for scene 2 (e.g., logo)
  gifSrc: string;        // Single gif for scene 2
}

// Use forwardRef to allow parent component to control animation
const OnboardingEndingAnimation = forwardRef(({
    onboardingText,
    scene1Text2,
    imgSrc,
    gifSrc,
}: OnboardingEndingAnimationProps, ref) => {
  const text1Refs = useRef<(HTMLDivElement | null)[]>([]); // Array of refs for each text in scene1Text1
  const scene1Text2Ref = useRef<HTMLDivElement | null>(null);
  const revealBoxRef = useRef<HTMLDivElement | null>(null);
  const botsaLogoRef = useRef<HTMLDivElement | null>(null);
  const botsaGifContainerRef = useRef<HTMLDivElement | null>(null);
  const timeline = useRef<gsap.core.Timeline | null>(null); // Store the timeline

  useEffect(() => {
    // Create the GSAP timeline
    timeline.current = gsap.timeline();

    // Animate each text in scene1Text1 one at a time
    onboardingText.forEach((_, index) => {
      const text1Ref = text1Refs.current[index];
      timeline.current!.fromTo(
        text1Ref,
        { y: "50px", opacity: 0 },
        { y: "0px", opacity: 1, duration: 1, ease: "power3.out" }
      );
      timeline.current!.to(text1Ref, { opacity: 0, duration: 1 }, "+=2"); // Show the text for 2 seconds, then fade out
    });

    // Reveal box animation
    timeline.current.fromTo(revealBoxRef.current, { opacity: 0 }, { opacity: 1 });
    timeline.current.to(revealBoxRef.current, 0.2, { width: "100%" }, "+=.5");
    timeline.current.to(revealBoxRef.current, { right: "0" });
    timeline.current.to(revealBoxRef.current, 0.2, { width: "0%" });

    // Scene 1 - Text 2 and Botsa logo
    timeline.current.fromTo(scene1Text2Ref.current, { opacity: 0 }, { opacity: 1 }, "-=.5");
    timeline.current.to(revealBoxRef.current, 0, { backgroundColor: "black" });
    timeline.current.to(scene1Text2Ref.current, { opacity: 0 }, "+=2");

    // Scale Botsa logo
    timeline.current.fromTo(botsaLogoRef.current, { opacity: 0 }, { opacity: 1 }, "-=.5");
    timeline.current.to(botsaLogoRef.current, {
      scale: 4,
      duration: 3,
      ease: "power2.out",
    });
    timeline.current.to(botsaLogoRef.current, { opacity: 0 }, "+=2");

    // Show Botsa GIF
    timeline.current.fromTo(
      botsaGifContainerRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 2, ease: "power2.out" },
      "+=0.5"
    );

  }, [onboardingText]);

  // Expose methods to control the timeline from the parent component
  useImperativeHandle(ref, () => ({
    restart: () => {
      if (timeline.current) {
        timeline.current.restart(); // Restart the animation timeline
      }
    },
  }));

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      {/* Scene 1 - Text 1 (loop over array of texts) */}
      {onboardingText.map((text, index) => (
        <div
          key={index}
          ref={(el) => (text1Refs.current[index] = el)} // Assign ref dynamically
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "24px",
            opacity: 0, // Start hidden
          }}
        >
          {text}
        </div>
      ))}

      {/* Reveal box */}
      <div
        ref={revealBoxRef}
        style={{
          position: "absolute",
          top: "50%",
          right: "50%",
          width: "0%",
          height: "5px",
          backgroundColor: "#EC572E",
          opacity: 0,
        }}
      ></div>

      {/* Scene 1 - Text 2 */}
      <div
        ref={scene1Text2Ref}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "24px",
          opacity: 0, // Start hidden
        }}
      >
        {scene1Text2}
      </div>

      {/* Botsa logo */}
      <div
        ref={botsaLogoRef}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "48px",
          opacity: 0, // Start hidden
        }}
      >
        <img src={imgSrc} alt="Botsa Logo" style={{ width: "100px" }} />
      </div>

      {/* Botsa GIF */}
      <div
        ref={botsaGifContainerRef}
        style={{
          position: "absolute",
          top: "70%",
          left: "40%",
          transform: "translate(-50%, -50%)",
          opacity: 0,
          height: "800px",
          width: "800px",
        }}
      >
        <img src={gifSrc} alt="Botsa Demo" style={{ width: "1000px", height: "auto" }} />
      </div>
    </div>
  );
});

export default OnboardingEndingAnimation;
